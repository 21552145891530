// Libs
import { call, put } from "redux-saga/effects";

// Utils

import { POST, GET } from "@Utils/HttpRequest";
import { Application } from "@Cores/Constants/Application";

export function* filterAction(options, values) {
  const { requestUrl, redirectUrl, redirectId, successAction, onSuccess } =
    options;
  yield put({
    type: Application.REQUEST_STATUS,
    payload: { requestStatus: true },
  });
  const response = yield call(POST, requestUrl, values);
  if (!response.success) {
    alert("Something wrong");
    return;
  }
  if (successAction) {
    yield* put(successAction(response));
  }
  if (onSuccess) {
    yield* onSuccess(response);
  }
  yield put({
    type: Application.REQUEST_STATUS,
    payload: { requestStatus: false },
  });
  if (redirectUrl) {
    // if (typeof redirectUrl == "function") {
    //   history.push(redirectUrl(response));
    // } else if (redirectId) {
    //   history.push(`${redirectUrl}/${response.id}`);
    // } else {
    //   history.push(`${redirectUrl}`);
    // }
  }
}

export function* filterGetAction(options, values) {
  const { requestUrl, redirectUrl, redirectId, successAction, onSuccess } =
    options;
  yield put({
    type: Application.REQUEST_STATUS,
    payload: { requestStatus: true },
  });
  const response = yield call(GET, requestUrl, values);
  if (!response.success) {
    alert("Something wrong");
    return;
  }
  if (successAction) {
    yield* put(successAction(response));
  }
  if (onSuccess) {
    yield* onSuccess(response);
  }
  yield put({
    type: Application.REQUEST_STATUS,
    payload: { requestStatus: false },
  });
  if (redirectUrl) {
    // if (typeof redirectUrl == "function") {
    //   history.push(redirectUrl(response));
    // } else if (redirectId) {
    //   history.push(`${redirectUrl}/${response.id}`);
    // } else {
    //   history.push(`${redirectUrl}`);
    // }
  }
}

// Edit Action
export const createEditAction = (options, values) => {
  const { dispatch, redirectUrl, serviceMethod, successAction, onSuccess } =
    options;

  return serviceMethod(values)
    .then((json) => {
      if (successAction) {
        dispatch(successAction(json));
      }
      if (onSuccess) {
        onSuccess(json);
      }
      if (redirectUrl) {
        // if (typeof redirectUrl == "function") {
        //   history.push(redirectUrl(json));
        // } else {
        //   history.push(`${redirectUrl}/${json.id}`);
        // }
      }
    })
    .catch((error) => {
      return error;
    });
};
