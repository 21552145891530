import React from "react";
import { Link } from "react-router-dom";

export const NavbarItem = ({ url, title, id }) => {
  return (
    <li key={id} className="nav-item">
      <Link className="nav-link" to={url}>
        {title}
      </Link>
    </li>
  );
};
