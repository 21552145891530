import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Menu, Dropdown, Button } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentDots, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";

// Actions
import { changeLanguage } from "@Cores/Actions/Application";
import i18n from "@Components/Translator/";

// Images
import Logo from "@Infrastructure/Assets/Natha/Images/logo-svg.svg";
import "./NathaHeader.less";

export const NathaHeader = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const menu = (
    <Menu selectedKeys={i18n.language}>
      <Menu.Item key="en">
        <Button type="text" onClick={() => onChangeLanguage("en")}>
          ENGLISH
        </Button>
      </Menu.Item>
      <Menu.Item key="km">
        <Button type="text" onClick={() => onChangeLanguage("km")}>
          ខ្មែរ
        </Button>
      </Menu.Item>
    </Menu>
  );
  const onChangeLanguage = (lang) => {
    history.push("/");
    dispatch(changeLanguage(lang));
    i18n.changeLanguage(lang);
  };
  return (
    <header className="container d-none d-md-none d-sm-none d-lg-block ">
      <div className="natha-header">
        <div className="natha-contact">
          <a href="sms:+098304333" className="natha-btn natha-blank-btn">
            <span>
              <FontAwesomeIcon className="" icon={faCommentDots} />
            </span>
          </a>
          <a href="tel:098304333" className="natha-btn natha-bg-btn">
            <FontAwesomeIcon className="" icon={faPhoneAlt} />
          </a>
          <a href="tel:098304333" className="natha-btn natha-phone">
            <span>
              <span className="btn-txt">098 304 333</span>
              <span className="btn-icon">
                <i className="fas fa-phone-alt"></i>
              </span>{" "}
            </span>
          </a>
        </div>
        <div className="natha-logo">
          <img src={Logo} alt="" />
        </div>
        <div className="natha-setting">
          <a
            href="https://www.facebook.com/Natharesidences/"
            className="natha-btn natha-social-color"
          >
            <FontAwesomeIcon className="fa-2x" icon={faFacebook} />
          </a>
          <a
            href="https://instagram.com/natharesidences?utm_medium=copy_link"
            className="natha-btn natha-social-color"
          >
            <FontAwesomeIcon className="fa-2x" icon={faInstagram} />
          </a>
          <Dropdown
            className="natha-language-selector"
            overlay={menu}
            trigger={["click"]}
          >
            <a
              href="/"
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              {i18n.language === "km" ? "ខ្មែរ" : "ENGLISH"} <DownOutlined />
            </a>
          </Dropdown>
        </div>
      </div>
    </header>
  );
};
