import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Logo from "@Infrastructure/Assets/Images/logo.png";
import { useTranslation } from "react-i18next";
import { getFsaDepartment } from "@Websites/FSA/Actions/";
import { SiteStatistic } from "@Cores/Components/Shared/Site-Statistic/SiteStatistic";

const FSAFooter = ({ departments, lang, getFsaDepartmentDispatch }) => {
  useEffect(() => {
    getFsaDepartmentDispatch();
  }, [lang]);
  const { t } = useTranslation();
  return (
    <footer className="bg-dark" id="tempaltemo_footer">
      <div className="container">
        <div className="row">
          <div className="col-md-4 pt-5">
            <h2 className="h3 text-success border-bottom pb-3 border-light logo">
              <img src={Logo} alt="LOGO" />
            </h2>
            <ul className="list-unstyled text-light footer-link-list">
              <li>
                <i className="fas fa-map-marker-alt fa-fw"></i>
                {t("address")}
              </li>
              <li>
                <i className="fa fa-phone fa-fw"></i>
                <a className="text-decoration-none" href="tel:023 990 700">
                  023 990 700
                </a>
              </li>
              <li>
                <i className="fa fa-envelope fa-fw"></i>
                <a
                  className="text-decoration-none"
                  href="mailto:info@fsa.gov.kh"
                >
                  info@fsa.gov.kh
                </a>
              </li>
            </ul>
          </div>

          <div className="col-md-4 pt-5">
            <h2 className="h3 text-light border-bottom pb-3 border-light">
              {t("news")}
            </h2>
            <ul className="list-unstyled text-light footer-link-list">
              <li>
                <Link to="/" className="text-decoration-none">
                  អនុក្រឹត្យលេខ១១៣ អនក្រ បក
                </Link>
              </li>
              <li>
                <Link to="/" className="text-decoration-none">
                  ច្បាប់ស្តីពីការរៀបចំនងការប្រព្រឹត្តទៅរប់ស FSA
                </Link>
              </li>
            </ul>
          </div>

          <SiteStatistic />
        </div>

        {/* <div className="row text-light mb-4">
          <div className="col-12 mb-3">
            <div className="w-100 my-3 border-top border-light"></div>
          </div>
          <div className="col-auto me-auto">
            <ul className="list-inline text-left footer-icons">
              <li className="list-inline-item border border-light rounded-circle text-center">
                <a
                  className="text-light text-decoration-none"
                  href="http://facebook.com/"
                >
                  <i className="fab fa-facebook-f fa-lg fa-fw"></i>
                </a>
              </li>
              <li className="list-inline-item border border-light rounded-circle text-center">
                <a
                  className="text-light text-decoration-none"
                  href="https://www.instagram.com/"
                >
                  <i className="fab fa-instagram fa-lg fa-fw"></i>
                </a>
              </li>
              <li className="list-inline-item border border-light rounded-circle text-center">
                <a
                  className="text-light text-decoration-none"
                  href="https://twitter.com/"
                >
                  <i className="fab fa-twitter fa-lg fa-fw"></i>
                </a>
              </li>
              <li className="list-inline-item border border-light rounded-circle text-center">
                <a
                  className="text-light text-decoration-none"
                  href="https://www.linkedin.com/"
                >
                  <i className="fab fa-linkedin fa-lg fa-fw"></i>
                </a>
              </li>
            </ul>
          </div>
        </div> */}
      </div>

      <div className="w-100 bg-black py-3">
        <div className="container">
          <div className="row pt-2">
            <div className="col-12">
              <p className="text-left text-light">
                Copyright &copy; 2021 FSA | Designed by{" "}
                <a href="https://geek-creative.live" rel="sponsored">
                  Geek IT
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

const mapStateToProps = (state) => {
  return {
    departments: state.fsa.departments,
    lang: state.application.lang,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFsaDepartmentDispatch: () => dispatch(getFsaDepartment()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FSAFooter);
