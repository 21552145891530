import { all, fork } from "redux-saga/effects";
import Application from "@Cores/Sagas/Application";
import FSA from "@Websites/FSA/Sagas/";
import Natha from "@Websites/Natha/Saga/Natha";

/**
 * rootSaga
 */
export default function* root() {
  yield all([fork(Application), fork(FSA), fork(Natha)]);
}
