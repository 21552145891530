export const FSA = {
  GET_ARTICLES: "GET_ARTICLES",
  GET_ARTICLES_SUCCESS: "GET_ARTICLES_SUCCESS",

  GET_ARTICLES_DETAIL: "GET_ARTICLES_DETAIL",
  GET_ARTICLES_DETAIL_SUCCESS: "GET_ARTICLES_DETAIL_SUCCESS",

  COUNT_ARTICLES: "COUNT_ARTICLES",
  COUNT_ARTICLES_SUCCESS: "COUNT_ARTICLES_SUCCESS",

  GET_FSA_DEPARTMENT: "GET_FSA_DEPARTMENT",
  GET_FSA_DEPARTMENT_SUCCESS: "GET_FSA_DEPARTMENT_SUCCESS",
};
