import React from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Flag from "react-flagkit";
import { changeLanguage } from "@Cores/Actions/Application";
import i18n from "@Components/Translator/";
import { Form, Input } from "antd";
import { MailOutlined, PhoneOutlined } from "@ant-design/icons";

export const FSANavbar = ({
  language,
  getMenuDispatch,
  changeLanguageDispatch,
}) => {
  const onChangeLanguage = (lang) => {
    changeLanguageDispatch(lang);
    i18n.changeLanguage(lang);
  };
  const history = useHistory();

  const onSearch = (value) => {
    history.push(`/fsa/articles/${value.searchText}`);
  };

  const { t } = useTranslation();

  return (
    <nav
      className="navbar navbar-expand-lg bg-dark navbar-light d-none d-lg-block"
      id="templatemo_nav_top"
    >
      <div className="container text-light">
        <div className="w-100 d-flex justify-content-between">
          <div className="align-middle-verticle">
            <MailOutlined className="no-margin" />
            <a
              className="navbar-sm-brand  text-decoration-none"
              href="mailto:info@company.com"
            >
              {" "}
              info@fsa.gov.kh
            </a>
            {"  "}
            <PhoneOutlined />{" "}
            <a
              className="navbar-sm-brand  text-decoration-none"
              href="tel:023 990 700"
            >
              023 990 700
            </a>
          </div>
          <div className="d-flex">
            <Form className="form-search" layout="inline" onFinish={onSearch}>
              <Form.Item name="searchText">
                <Input.Search
                  placeholder={t("search")}
                  onSearch={onSearch}
                  size="small"
                  enterButton
                />
              </Form.Item>
            </Form>
            <Link to="/" onClick={() => onChangeLanguage("km")} className="m-2">
              {/* <Avatar style={{ backgroundColor: "#59ab6e" }}>ខ្មែរ</Avatar> */}
              <Flag country="KH" />
            </Link>
            <Link to="/" onClick={() => onChangeLanguage("en")} className="m-2">
              {/* <Avatar style={{ backgroundColor: "#59ab6e" }}>Eng</Avatar> */}
              <Flag country="US" />
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.application.lang,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeLanguageDispatch: (lang) => dispatch(changeLanguage(lang)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FSANavbar);
