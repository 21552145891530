/**
 * @module Sagas/GitHub
 * @desc Leave
 */

import { all, put, takeLatest } from "redux-saga/effects";
import { filterAction } from "@Infrastructure/Utils/ActionCreator";
import { Natha } from "../Constants/Natha";
/**
 * Authentication
 *
 * @param {Object} action
 *
 */

// success
function* getSubmitQuizSuccess(res) {
  console.log(res);
  yield put({
    type: Natha.SUBMIT_QUIZ_SUCCESS,
    payload: { quizResult: res.result },
  });
}

export function* submitQuiz({ payload }) {
  console.log(payload);
  const option = {
    requestUrl: `api/natha-registrant/quiz`,
    onSuccess: getSubmitQuizSuccess,
    // redirectUrl: "/natha/quiz-result",
  };
  yield* filterAction(option, payload.quiz);
}

/**
 * Customer Sagas
 */
export default function* root() {
  yield all([takeLatest(Natha.SUBMIT_QUIZ, submitQuiz)]);
}
