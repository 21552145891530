import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./NathaNavbar.less";

export const NathaNavbar = () => {
  // fields
  const [collapse, setCollapse] = useState(false);
  const { t } = useTranslation();

  // event
  const onSidebarClick = () => {
    collapse === "show" ? setCollapse("") : setCollapse("show");
  };

  return (
    <React.Fragment>
      <nav className="navbar navbar-expand-lg main-navbar">
        <div className="container d-flex justify-content-between align-items-center">
          <button
            className="navbar-toggler border-0"
            type="button"
            onClick={onSidebarClick}
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className={`align-self-center collapse navbar-collapse   d-lg-flex justify-content-lg-between ${collapse}`}
            id="natha-main-nav"
          >
            <div className="flex-fill">
              <ul className="nav navbar-nav d-flex">
                <li className="nav-item">
                  <Link className="nav-link " to="/">
                    {t("nathaHome")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link " to="/natha/about">
                    {t("nathaAbout")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link " to="/natha/projects">
                    {t("nathaProject")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link " to="/natha/contact">
                    {t("nathaContact")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={{ pathname: "https://virtual.natharesidenceskh.com/" }}
                    target="_blank"
                  >
                    {t("nathaVirtual")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link " to="/natha/quiz">
                    {t("nathaQuiz")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
};
