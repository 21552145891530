import React from "react";

import { NathaLayout } from "@Websites/Natha/Components/Shared/Layouts/Natha.Layout";
import { NathaBanner } from "@Websites/Natha/Components/Shared/Banner/Natha.Banner";
import { NathaHomeInterior } from "./Sections/Natha.Home.Interior";
import { NathaHomeBoulevard } from "./Sections/Natha.Home.Boulevard";
import { NathaHomeWood } from "./Sections/Natha.Home.Wood";

import "./NathaHome.less";

export const NathaHome = () => {
  return (
    <NathaLayout>
      <NathaBanner />
      <NathaHomeInterior />
      <NathaHomeWood />
      <NathaHomeBoulevard />
    </NathaLayout>
  );
};
