import React from "react";

import { CopyrightOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";

import Logo from "@Infrastructure/Assets/Natha/Images/logo-svg.svg";

import "./NathaFooter.less";

export const NathaFooter = () => {
  return (
    <footer className="natha-footer">
      <div className="container">
        <img src={Logo} alt="Logo" className="natha-footer-logo" />
        <p className="natha-footer-copyright ">
          Copyright <CopyrightOutlined /> 2021. All Rights Reserved.
        </p>
        <div className="natha-footer-social">
          <a
            href="https://www.facebook.com/Natharesidences/"
            className="natha-btn natha-bg-btn bg-primary natha-footer-facebook"
          >
            <FontAwesomeIcon className="fa-lg" icon={faFacebookF} />
          </a>
          <a
            href="https://instagram.com/natharesidences?utm_medium=copy_link"
            className="natha-btn natha-bg-btn bg-primary natha-footer-instagram"
          >
            <FontAwesomeIcon className="fa-lg" icon={faInstagram} />
          </a>
        </div>
      </div>
    </footer>
  );
};
