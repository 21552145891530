/* eslint-disable import/no-anonymous-default-export */
import { handleActions } from "redux-actions";
import immutable from "immutability-helper";
import { Natha } from "@Websites/Natha/Constants/Natha";

export const nathaState = {
  quizResult: null,
};

export default {
  natha: handleActions(
    {
      [Natha.SUBMIT_QUIZ_SUCCESS]: (state, { payload }) => {
        const quizResult = payload.quizResult || undefined;
        return immutable(state, {
          quizResult: { $set: quizResult },
        });
      },
    },
    nathaState
  ),
};
