import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PlayCircleOutlined } from "@ant-design/icons";
import ModalVideo from "react-modal-video";

export const NathaHomeInterior = () => {
  const [youtube, setYoutube] = useState(false);
  const { t } = useTranslation();
  return (
    <section className="natha-page natha-page-interior">
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={youtube}
        videoId="H-gx4I5htx4"
        onClose={() => setYoutube(false)}
      />
      <div className="container interior-content">
        <h2 className="interior-title"> {t("nathaInteriorTitle")}</h2>
        <h3 className="interior-slogan">{t("nathaInteriorSlogan")}</h3>
        <p className="interior-description">{t("nathaInteriorDescription")}</p>
        <div className="interior-video">
          <div className="container class-test">
            <div className="interior-video-player">
              <PlayCircleOutlined
                twoToneColor="#FF1348"
                onClick={() => setYoutube(true)}
              />
            </div>
            <img
              src={`${process.env.PUBLIC_URL}/assets/img/Queen-villa-livingroom.jpg`}
              alt="interior"
            />
          </div>
        </div>
        <span className="next-page"></span>
      </div>
    </section>
  );
};
