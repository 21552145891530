import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { getFsaMenu, getFsaCarousel } from "@Cores/Actions/Application";
import { Link } from "react-router-dom";
import { NavbarItem } from "./NavbarItem";
import Logo from "@Infrastructure/Assets/Images/logo.png";

const FSAHeader = ({ menus, getMenuDispatch, lang }) => {
  // fields
  const [collapse, setCollapse] = useState(false);

  //constructor
  useEffect(() => {
    getMenuDispatch();
  }, [lang]);

  // event
  const onSidebarClick = () => {
    collapse === "show" ? setCollapse("") : setCollapse("show");
  };

  const subMenuRender = (subMenu, key) => {
    return (
      <li key={key}>
        <Link className="dropdown-item" to={subMenu.url}>
          {subMenu.SMenuName}
        </Link>
      </li>
    );
  };

  return (
    <React.Fragment>
      <nav className="navbar navbar-expand-lg navbar-light shadow">
        <div className="container d-flex justify-content-between align-items-center">
          <Link
            className="navbar-brand text-success logo h1 align-self-center"
            to="/"
          >
            <img src={Logo} alt="LOGO" />
          </Link>
          <button
            className="navbar-toggler border-0"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#templatemo_main_nav"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={onSidebarClick}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          {/* flex-fill */}
          <div
            className={`align-self-center collapse navbar-collapse   d-lg-flex justify-content-lg-between ${collapse}`}
            id="templatemo_main_nav"
          >
            <div className="flex-fill">
              <ul className="nav navbar-nav d-flex justify-content-between mx-lg-auto float-end">
                {/* <ul className="nav navbar-nav float-end"> */}
                {menus.map((menu, index) => {
                  if (menu.sub_menus.length > 0) {
                    return (
                      <li
                        key={`submenu-${index}`}
                        className="nav-item dropdown"
                      >
                        <Link
                          className="nav-link dropdown-toggle"
                          to="/fsa/about"
                        >
                          {menu.Name}
                        </Link>
                        <ul className="dropdown-menu">
                          {menu.sub_menus.map((subMenu, index) => {
                            return subMenuRender(subMenu, subMenu.id);
                          })}
                        </ul>
                      </li>
                    );
                  }
                  return (
                    <NavbarItem
                      url={menu.url}
                      title={menu.Name}
                      id={menu.id}
                      key={menu.id}
                    />
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    menus: state.application.menus,
    carousels: state.application.carousels,
    lang: state.application.lang,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMenuDispatch: () => dispatch(getFsaMenu()),
    getCarouselDispatch: () => dispatch(getFsaCarousel()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FSAHeader);
