import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";

const PublicRoute = ({ Component, ...rest }) => {
  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

PublicRoute.propTypes = {
  Component: PropTypes.object.isRequired,
};

export default PublicRoute;
