export const Application = {
  GET_FSA_MENU: "GET_FSA_MENU",
  GET_FSA_MENU_SUCCESS: "GET_FSA_MENU_SUCCESS",

  GET_FSA_CAROUSEL: "GET_FSA_CAROUSEL",
  GET_FSA_CAROUSEL_SUCCESS: "GET_FSA_CAROUSEL_SUCCESS",

  REQUEST_STATUS: "REQUEST_STATUS",
  CHANGE_LANGUAGE: "CHANGE_LANGUAGE",
};
