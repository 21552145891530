import app from "@Cores/Reducers/Application";
import fsa from "@Websites/FSA/Reducers/";
import natha from "@Websites/Natha/Reducers/Natha";
const RootReducer = {
  ...app,
  ...fsa,
  ...natha,
};

export default RootReducer;
