import React from "react";
// import PropTypes from "prop-types";
// import classNames from "classnames";

// const Loader = ({ spinning = false, fullScreen }) => {
const Loader = () => {
  return (
    // <div
    //   className={classNames(styles.loader, {
    //     // [styles.hidden]: !spinning,
    //     [styles.fullScreen]: fullScreen,
    //   })}
    // >
    //   <div className={styles.warpper}>
    //     <div className={styles.inner} />
    //     <div className={styles.text}>LOADING</div>
    //   </div>
    // </div>
    <div>Loader</div>
  );
};

// Loader.propTypes = {
//   spinning: PropTypes.bool,
//   fullScreen: PropTypes.bool,
// };

export default Loader;
