import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    km: {
      translations: {
        generalSecretariat: "អគ្គលេខាធិការដ្ឋាន",
        insuranceRegulator: "និយ័តករធានារ៉ាប់រងកម្ពុជា",
        securitiesAndExchangeRegulator: "និយ័តករមូលបត្រកម្ពុជា",
        socialSecurityRegulator: "និយតករសន្តិសុខសង្គម",
        trustRegulator: "និយ័តករបរធនបាលកិច្ច",
        accountingAndAuditingRegulation: "និយ័តករគណនេយ្យ និងសវនកម្ម",
        realEstateBusiness: "និយ័តករអាជីវកម្មអចលនវត្ថុ និងបញ្ចាំ",
        internationAuditUnit: "អង្គភាពសវនកម្មផ្ទៃក្នុង",
        onlineComplain: "ការដាក់បណ្តឹង",
        publicService: "សេវាសាធារណៈ",
        search: "ស្វែងរក",
        more: "ផ្សេងទៀត",
        news: "ព័ត៌មាន និងព្រឹតិការណ៍ថ្មីៗ",
        announcement: "សេចក្តីប្រកាស",
        publicForm: "ឯកសារសារធារណ",
        moreAboutUs: "ព័ត៌មានបន្ថែម",
        address:
          "អគារលេខ ១៦៨F ផ្លូវលេខ ៥៩៨ សង្កាត់ច្រាំងចំរេះ១ ខណ្ឌឬស្សីកែវ រាជធានីភ្នំពេញ កម្ពុជា",
        copyright: "Copyright 2021 FSA | Designed by Geek IT",
        download: "ទាញយក",
        contact: "ទំនាក់ទំនង",
        telephone: "លេខទូរស័ព្ទ",
        email: "Email",
        addressLabel: "អាស័យដ្ឋាន",
        fullname: "ឈ្មោះ",
        subject: "ប្រធានបទ",
        message: "សារ",
        send: "បញ្ជូន",
        totalUsers: "អ្នកមើលសរុប",
        todayCount: "ចំនួនថ្ងៃនេះ",
        yesterdayCount: "ចំនួនម្សិលមិញ",
        onlinUsers: "ចំនួនអ្នកកំពុងមើល",
        siteStatistic: "ចំនួនអ្នកបើកមើល",
        departmentHirachy: "អង្គភាពក្រោមចំណុះ អ.ស.ហ",
        nathaHome: "ទំព័រដើម",
        nathaAbout: "អំពីយើង",
        nathaProject: "គម្រោងរបស់យើង",
        nathaContact: "ទំនាក់ទំនង",
        nathaVirtual: "VIRTUAL TOUR 360",
        nathaQuiz: "PLAY AND WIN",
        nathaResidence: "ណាថា រ៉េស៊ីដិន",
        nathaCopyright: "២០២១ - រក្សាសិទ្ធគ្រប់យ៉ាងដោយ ណាថា រ៉េស៊ីដិន",
        nathaQueenVilla: "ផ្ទះវីឡាឃ្វីន",
        nathaTwinVilla: "ផ្ទះវីឡាភ្លោះ",
        nathaShophouse: "",
        nathaFlathouse: "ផ្ទះល្វែងកែង ឬ ចំហៀង",
        nathaLinkhouse: "ផ្ទះវីឡាកូនកាត់កែង",
        nathaFeature: "លក្ខណ:ពិសេស",
        nathaLandSize: "ទំហំដី",
        nathaGroupFloor: "ជាន់ផ្ទាល់ដី",
        nathaFirstFloor: "ជាន់ទី១",
        nathaSecondFloor: "ជាន់ទី២",
        nathaTopFloor: "ជាន់ដំបូល",
        nathaGallery: "បណ្តុំរូបភាព",
        // home
        nathaHomeSlogan: "មរតកភូមិគ្រឹះទំនើបស៊ីវិល័យជាទីប្រតិព័ទ្ធ",
        nathaHomeDescription:
          "​យើង​សូម​អញ្ជើញ​ លោក​ លោកស្រី អ្នក​នាង​កញ្ញា​ ​​ទទួល​យក​បទពិសោធន៍​ក្នុង​ការរស់នៅ ក្នុងស្ថាបត្យកម្ម​លំនៅឋានឈាន​មុខ​គេ​របស់​ ណាថា រ៉េស៊ីដិន។ ​យើង​ប្តេជ្ញាខ្ពស់ ​​ដើម្បី​ជ​ម្រុ​ញ​ការរស់នៅតាម​បែប​ប្រណីត​​ ​ដោយ​ប្រើ​វត្ថុធាតុដើម​ដែល​មានគុណ​ភាព​ និង​ភាព​ប៉ិនប្រសប់​នៃស្ថាបត្យកម្មរបស់យើង។​ ​យើង​ផ្តល់​ជូន​អ្នក​ស្វែងរក​ផ្ទះ​នូវជម្រើស​សម្បូរបែប មានដូចជា​ ​ផ្ទះល្វែង​ ​វី​ឡា​លីង​ ​វី​ឡា​ភ្លោះ ​និង​ ​វីឡាឃ្វី​នដោយមានរំលេច​លក្ខណៈ​ពិសេស​ផ្នែក​រចនា​បទ​ខាងក្នុង​ទំនើប​ ​និង​ទំហំ​សួនច្បារ​ធំដ៏ធំទូលាយ។",
        nathaInteriorTitle: "ការរចនាខាងក្នុងដ៏ទំនើប",
        nathaInteriorSlogan: "ដោយ ណាថា រ៉េស៊ីដិន",
        nathaInteriorDescription:
          // "រចនាបថ​​យ៉ាង​ប្រណីត​ដូចជាផ្ទះផុសកណ្តាលសួន​ ដែលមានលំហរនៃបន្ទប់ទទួលភ្ញៀវ​​២ជាន់មានកំពស់ ៧​ម៉ែត្រ ពីដី លើកសម្រស់ផ្ទៃបន្ទប់ទាំងមូល ហាក់ដូចជាវិមានដ៏ស្គឹមស្គៃផ្តល់ជូនម្ចាស់ផ្ទះ ភាពពេញចិត្ត ជាន់ទាំងមូល រំលេចបន្ទប់មេគ្រួសារ​ដែល​មាន​បន្ទប់ទឹក​ ​ទូរ​សំលៀកបំពាក់​ដ៏ធំ​ទូលាយ​ ​និង​តុ​ធ្វើការផ្ទាល់ខ្លួន​ ​ដើម្បី​បន្ថែម​ភាព​ងាយស្រួល​ ​ដល់​លោកអ្នក។",
          "",
        nathaTheWoodTitle: "The Woods",
        nathaTheWoodSlogan: "ផ្ទះអាជីវកម្ម",
        nathaTheWoodDescription:
          "T​h​e​ ​Woods ​ដោយ​ ណាថា​ រ៉េស៊ីដិន ​ផ្តល់​ឱ្យ​អ្នក​នូវ​ការ​បញ្ចូល​គ្នា​នៃ​ទេសភាព​ទីក្រុង​ ​និង​ការរស់នៅបែបស្ងប់ស្ងាត់ដ៏​ល្អ​បំផុត។ ​នេះ​ជា​ការ​ច្របាច់​បញ្ចូល​គ្នា​យ៉ាងល្អ​បំផុត​នៃ​បរិយាកាសទាំងពីរ លាតសន្ធឹង លើទឹកដី​ព្រែកឯង​ ​តាមបណ្តោយផ្លូវអាស៊ាន ហាយវ៉េលេខ១​។​ T​h​e​ ​Woods ​ត្រូវ​បាន​រចនា​ឡើង​សម្រាប់​អ្នក​ស្វែងរក​គេហដ្ឋាន​សម្រាប់​ការរស់នៅ​បែប​ទំនើប​ ​​និង​ ​ងាយស្រួល​ក្នុង​ការធ្វើដំណើរ​មកកាន់​ទីក្រុង​នៅពេលដែល​ត្រូវការ។ ​T​h​e​ ​Woods ​នឹង​ក្លាយ​ជា​ជម្រើស​ដ៏​ល្អ​បំផុត​សម្រាប់​អ្នក​ដែល​មានបំណង​រស់នៅ​ក្នុង​សហគមន៍​តូច​និង​មាន​សុវត្ថិភាព។",
        nathaTheBoulevardTitle: "The Boulevard",

        nathaTheBoulevardDescription:
          "The Boulevard ដោយ ណាថា​ រ៉េស៊ីដិនជា ស្នាដៃមរតកទី២​ របស់ពួកយើង ដែលផ្តល់ជូននូវផ្ទះអាជីវកម្មប្រមាណ ១០០ ផ្ទះ លាតសន្ធឹងលើ ផ្លូវ ហាយវ៉េ ហ៊ុមព័ទ្ធទៅដោយគម្រោងខ្នាតយក្សជាច្រើន មានដូចជា គម្រោងព្រលានយន្តហោះភ្នំពេញថ្មី គម្រោងផ្សារ អ៊ីអន ៣ដែលមានទំហំប្រមាណ ១៧ ហិចតា កន្លះ ផ្លូវក្រវ៉ាត់ក្រុងទី៣ បានធ្វើអោយ ឌឺ ប៊ូឡឺវ៉ាត ក្លាយជាការវិនិយោគ និងផ្ទះអាជីវកម្មដ៏មានសក្តានុពលបំផុត សម្រាប់ពាណិជ្ជករ និងអ្នកវិនិយោគទាំងនោះ ក្រៅពីទីតាំងដ៏ប្រណីត ឌឺ​ ប៊ូឡឺវ៉ាត ផ្តល់ជូននូវការរស់នៅដែលលើសពីផ្ទះអាជីវកម្មដ៏មានសក្តានុពល ដោយផ្តល់ជូននូវបន្ទប់ទទួលភ្ញៀវដ៏ធំទូលាយ លំហល្វឹងពីមុខដល់តុទទួលទានអាហារ អមដោយសួនច្បារកណ្តាលផ្ទះ និងដំបូលស្កាយឡាយ ផ្តល់ពន្លឺចូលពេញក្នុងផ្ទះ​ រួមជាមួយបរិយាកាសរស់នៅដ៏ស្រស់បំព្រង។",
        nathaAboutCEOTitle: "CEO Speech",
        nathaAboutCEODescription:
          "Natha Residences_ a Premier Residential Developer currently builds The Woods and The Boulevard. Our Commitment is to provide an inspired luxury modern living utilizing the highest quality in craftsmanship. We offer homeseekers a Unique Collection of Shop Houses, Link House, Twin Villa, Queen and King Villas.",
        nathaProjectTheWoodTitle: "The Woods Project",
        nathaProjectTheWoodDescription:
          "The Woods by Natha Residences gives you the ideal blend of a city and escape. The best of both worlds is located at Preak Eng, National Road 1. It is designed for those who seek sanctuary for their Luxury Modern Livings and be able to access the center of the city whenever their hearts desire. The woods will be the best option for any homeseekers wishing to live in a medium size and safe community.",
        nathaProjectTheBoulevardTitle: "The Boulevard",
        nathaProjectTheBoulevardDescription:
          "The Boulevard by Natha Residences is the second Legacy of US, Presented almost a Hundred of Modern Cozy Shop Houses on the National HighWay surrounded by plenty of Mega Projects such as new Phnom Penh International Airport, the 17.5 h.a Third AEON Mall, 3rd Phnom Penh Ring Road embolding The Boulevard a Very Potential Investment and Home Business for those traders and Investors. Beside the greatest location, The Boulevard offers a Unique Modern Living beyonds the Shop Houses giving dwellers a Large Living Room, a Free Open Space from The Living Room to the Clean Cozy Dining Table with in-house Garden and Sky Light Roofing providing the whole house full of light and a Fresh Living Ambience.",
      },
    },
    en: {
      translations: {
        generalSecretariat: "General Secretariat",
        insuranceRegulator: "Insurance Regulator",
        securitiesAndExchangeRegulator: "Securities And Exchange Regulator",
        socialSecurityRegulator: "Social Security Regulator",
        trustRegulator: "Trust Regulator",
        accountingAndAuditingRegulation: "Accounting And Auditing Regulation",
        realEstateBusiness: "Real Estate Business",
        internationAuditUnit: "Internation Audit Unit",
        publicService: "Public services",
        onlineComplain: "Online Complain",
        search: "Search",
        more: "More",
        news: "News",
        announcement: "Announcement",
        publicForm: "Public Form",
        moreAboutUs: "More about us",
        address:
          "Building No 168F St 598 Chrang Chamres 1, Russey Keo, Phnom Penh, Cambodia",
        copyright: "Copyright 2021 FSA | Designed by Geek IT",
        download: "Download",
        contact: "Contact us",
        telephone: "Telephone",
        email: "Email",
        addressLabel: "Address",
        fullname: "Fullname",
        subject: "Subject",
        message: "Message",
        send: "Send",
        totalUsers: "Total",
        todayCount: "Today",
        yesterdayCount: "Yesterday",
        onlinUsers: "Online",
        siteStatistic: "Website Statistic",
        departmentHirachy: "Unit Under FSA",
        nathaHome: "HOME",
        nathaAbout: "ABOUT US",
        nathaProject: "OUR PROJECTS",
        nathaContact: "CONTACT US",
        nathaVirtual: "VIRTUAL TOUR 360",
        nathaQuiz: "PLAY AND WIN",
        nathaResidence: "NATHA RESIDENCES",
        nathaCopyright: "២០២១ - រក្សាសិទ្ធគ្រប់យ៉ាងដោយ ណាថា រ៉េស៊ីដិន",
        nathaQueenVilla: "ផ្ទះវីឡាឃ្វីន",
        nathaTwinVilla: "ផ្ទះវីឡាភ្លោះ",
        nathaShophouse: "",
        nathaFlathouse: "ផ្ទះល្វែងកែង ឬ ចំហៀង",
        nathaLinkhouse: "ផ្ទះវីឡាកូនកាត់កែង",
        nathaFeature: "លក្ខណ:ពិសេស",
        nathaLandSize: "ទំហំដី",
        nathaGroupFloor: "ជាន់ផ្ទាល់ដី",
        nathaFirstFloor: "ជាន់ទី១",
        nathaSecondFloor: "ជាន់ទី២",
        nathaTopFloor: "ជាន់ដំបូល",
        nathaGallery: "បណ្តុំរូបភាព",
        // home
        nathaHomeSlogan: "An Inspired Luxurious Modern Living Legacy",
        nathaHomeDescription:
          "We cordially invite you to experience living in our premier residences. Our commitment is to provide beyond a home in an inspired luxurious modern lifestyle, with the exceptionally high quality materials and craftsmanships. We offer home seekers a unique collection of Shop Houses, Links, Twins and Queen Villas featuring modern interior and ample green space.",
        nathaInteriorTitle: "Modern Interior",
        nathaInteriorSlogan: "by natha residences",
        nathaInteriorDescription:
          //"An Elegantly Designed Floating Pavilion Like, the Living room’s double height ceilings of 7 meteres off the ground  bolding the second floor of any Natha’s Villa Hall, a Palace sized offering the dwellers the satisfaction no less than a Penthouse. The Entire Floor highlights the Master Bedroom enclosed with an En-Suite Bathroom, a Walk-in Closet for extra convenience and a Personal Homeoffice.",
          "",
        nathaTheWoodTitle: "The Woods",

        nathaTheWoodDescription:
          "The Woods by Natha Residences gives you the ideal blend of a city and escape. The best of both worlds is located at Preak Eng, National Road 1. It is designed for those who seek sanctuary for their Luxury Modern Livings and be able to access the center of the city whenever their hearts desire. The Woods will be the best option for any homeseekers wishing to live in a medium size and safe community.",
        nathaTheBoulevardTitle: "The Boulevard",
        nathaTheBoulevardDescription:
          "The Boulevard by Natha Residences is the second Legacy of us, presented almost a hundred of modern cozy Shop Houses on the National HighWay surrounded by plenty of Mega Projects such as new Phnom Penh International Airport, the 17.5 h.a Third AEON Mall, 3rd Phnom Penh Ring Road embolding The Boulevard a very potential investment and home business for those traders and investors. Beside the greatest location, The Boulevard offers a unique modern living beyonds the regualar Shop Houses, giving dwellers a large living room, a free open space from the Living Room to the clean cozy dining table with in-house Garden and Sky Light Roofing providing the whole house full of light and a fresh living ambience.",
        nathaAboutCEOTitle: "CEO Speech",
        nathaAboutCEODescription:
          "Natha Residences_ a Premier Residential Developer currently builds The Woods and The Boulevard. Our Commitment is to provide an inspired luxury modern living utilizing the highest quality in craftsmanship. We offer homeseekers a Unique Collection of Shop Houses, Link House, Twin Villa, Queen and King Villas.",
        nathaProjectTheWoodTitle: "The Woods Project",
        nathaProjectTheWoodDescription:
          "The Woods by Natha Residences gives you the ideal blend of a city and escape. The best of both worlds is located at Preak Eng, National Road 1. It is designed for those who seek sanctuary for their Luxury Modern Livings and be able to access the center of the city whenever their hearts desire. The Woods will be the best option for any homeseekers wishing to live in a medium size and safe community.",
        nathaProjectTheBoulevardTitle: "The Boulevard",
        nathaProjectTheBoulevardDescription:
          "The Boulevard by Natha Residences is the second Legacy of us, presented almost a hundred of modern cozy Shop Houses on the National HighWay surrounded by plenty of Mega Projects such as new Phnom Penh International Airport, the 17.5 h.a Third AEON Mall, 3rd Phnom Penh Ring Road embolding The Boulevard a very potential investment and home business for those traders and investors. Beside the greatest location, The Boulevard offers a unique modern living beyonds the regualar Shop Houses, giving dwellers a large living room, a free open space from the Living Room to the clean cozy dining table with in-house Garden and Sky Light Roofing providing the whole house full of light and a fresh living ambience.",
      },
    },
  },
  fallbackLng: "en",
  lng: "en",
  debug: true,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ",",
  },

  react: {
    wait: true,
  },
});

export default i18n;
