import createSagaMiddleware from "redux-saga";
import { routerMiddleware } from "connected-react-router";
import RouteHistory from "@Utils/RouteHistory";

export const sagaMiddleware = createSagaMiddleware();

const Middleware = [routerMiddleware(RouteHistory), sagaMiddleware];

/* istanbul ignore next */
if (process.env.NODE_ENV === "development") {
  const { createLogger } = require("redux-logger");
  const invariant = require("redux-immutable-state-invariant").default;

  Middleware.push(invariant());
  Middleware.push(createLogger({ collapsed: true }));
}

export default Middleware;
