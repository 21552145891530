import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Menu, Dropdown, Button, Drawer } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import {
  MenuOutlined,
  DownOutlined,
  PieChartOutlined,
  DesktopOutlined,
  ContainerOutlined,
} from "@ant-design/icons";
import { changeLanguage } from "@Cores/Actions/Application";
import i18n from "@Components/Translator/";

// Images
import Logo from "@Infrastructure/Assets/Natha/Images/logo-svg.svg";

import "./NathaHeader.less";

export const NathaHeaderMobile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [menuVisible, setMenuVisible] = useState(false);

  const onMenuShowClick = () => {
    setMenuVisible(true);
  };

  const onChangeLanguage = (lang) => {
    history.push("/");
    dispatch(changeLanguage(lang));
    i18n.changeLanguage(lang);
  };

  const menu = (
    <Menu>
      <Menu.Item key="en">
        <Button type="text" onClick={() => onChangeLanguage("en")}>
          ENGLISH
        </Button>
      </Menu.Item>
      <Menu.Item key="km">
        <Button type="text" onClick={() => onChangeLanguage("km")}>
          ខ្មែរ
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <header className="d-lg-none d-xl-none d-sm-block d-md-block">
      <Drawer
        placement="left"
        className="natha-header-mobile-drawer"
        onClose={() => setMenuVisible(false)}
        visible={menuVisible}
      >
        <Menu
          className="natha-font"
          defaultSelectedKeys={["1"]}
          mode="inline"
          theme="light"
        >
          <Menu.Item key="1" icon={<PieChartOutlined />}>
            <Link to="/">HOME</Link>
          </Menu.Item>
          <Menu.Item key="2" icon={<DesktopOutlined />}>
            <Link to="/natha/about">ABOUT US</Link>
          </Menu.Item>
          <Menu.Item key="3" icon={<ContainerOutlined />}>
            <Link to="/natha/projects">OUR PROJECTS</Link>
          </Menu.Item>
          <Menu.Item key="4" icon={<ContainerOutlined />}>
            <Link to="/natha/contact">CONTACT US</Link>
          </Menu.Item>
          <Menu.Item key="5" icon={<ContainerOutlined />}>
            <Link
              to={{ pathname: "https://virtual.natharesidenceskh.com/" }}
              target="_blank"
            >
              VIRTUAL TOUR 360
            </Link>
          </Menu.Item>
          <Menu.Item key="6" icon={<ContainerOutlined />}>
            <Link to="/natha/quiz">PLAY AND WIN</Link>
          </Menu.Item>
        </Menu>
        <div className="flex items-center mx-5">
          <a
            href="https://www.facebook.com/Natharesidences/"
            className="natha-btn natha-social-color"
          >
            <FontAwesomeIcon className="fa-2x" icon={faFacebook} />
          </a>
          <a
            href="https://instagram.com/natharesidences?utm_medium=copy_link"
            className="natha-btn natha-social-color"
          >
            <FontAwesomeIcon className="fa-2x" icon={faInstagram} />
          </a>
        </div>
      </Drawer>
      <div className="natha-header-mobile">
        <div className="natha-contact">
          <Button
            onClick={onMenuShowClick}
            type="link"
            size="large"
            icon={<MenuOutlined />}
          />
        </div>
        <div className="natha-logo-mobile">
          <img src={Logo} alt="" />
        </div>
        <div className="natha-setting pull-right">
          <Dropdown
            className="natha-language-selector"
            overlay={menu}
            trigger={["click"]}
          >
            <a
              href="/"
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              {i18n.language === "km" ? "ខ្មែរ" : "ENGLISH"} <DownOutlined />
            </a>
          </Dropdown>
        </div>
      </div>
    </header>
  );
};
