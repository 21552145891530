/**
 * @module Sagas/GitHub
 * @desc Leave
 */

import { all, put, takeLatest } from "redux-saga/effects";
import { filterGetAction } from "@Infrastructure/Utils/ActionCreator";
import { Application } from "../Constants/Application";
import i18n from "@Components/Translator/";
/**
 * Authentication
 *
 * @param {Object} action
 *
 */

// success
function* onGetCarouselSuccess(res) {
  yield put({
    type: Application.GET_FSA_CAROUSEL_SUCCESS,
    payload: { carousels: res.result.json() },
  });
}

function* onGetMenuSuccess(res) {
  yield put({
    type: Application.GET_FSA_MENU_SUCCESS,
    payload: { menus: res.result },
  });
}

export function* getMenu({ payload }) {
  const option = {
    requestUrl: `website-menus?_locale=${i18n.language}`,
    onSuccess: onGetMenuSuccess,
  };
  yield* filterGetAction(option, payload);
}

export function* getCarousel({ payload }) {
  const option = {
    requestUrl: `/api/leave/v1/lists`,
    onSuccess: onGetCarouselSuccess,
  };
  yield* filterGetAction(option, payload);
}

/**
 * Customer Sagas
 */
export default function* root() {
  yield all([takeLatest(Application.GET_FSA_MENU, getMenu)]);
  yield all([takeLatest(Application.GET_FSA_CAROUSEL, getCarousel)]);
}
