import { handleActions } from "redux-actions";
import immutable from "immutability-helper";
import { FSA } from "../Constants/";

export const fsaState = {
  articles: [],
  article: null,
  departments: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  fsa: handleActions(
    {
      [FSA.GET_ARTICLES_SUCCESS]: (state, { payload }) => {
        const articles = payload.articles || undefined;
        return immutable(state, {
          articles: { $set: articles },
        });
      },
      [FSA.GET_ARTICLES_DETAIL_SUCCESS]: (state, { payload }) => {
        const article = payload.article || undefined;
        return immutable(state, {
          article: { $set: article },
        });
      },
      [FSA.GET_FSA_DEPARTMENT_SUCCESS]: (state, { payload }) => {
        const departments = payload.departments || undefined;
        return immutable(state, {
          departments: { $set: departments },
        });
      },
    },
    fsaState
  ),
};
