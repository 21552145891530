import React from "react";

import { Indicator } from "@Cores/Components/Shared/Indicator";
import { NathaHeader } from "@Websites/Natha/Components/Shared/Header/Natha.Header";
import { NathaHeaderMobile } from "@Websites/Natha/Components/Shared/Header/Natha.Header.Mobile";
import { NathaNavbar } from "@Websites/Natha/Components/Shared/Navbar/Natha.Navbar";

import { NathaFooter } from "@Websites/Natha/Components/Shared/Footer/Natha.Footer";

import "@Infrastructure/Themes/natha.less";

export const NathaLayout = ({ children }) => {
  return (
    <>
      <div className="natha-wrapper">
        <Indicator />
        <NathaHeaderMobile />
        <NathaHeader />
        <NathaNavbar />

        {children}
        <NathaFooter />
      </div>
    </>
  );
};
