import React from "react";
import { useTranslation } from "react-i18next";

import "./SiteStatistic.less";

export const SiteStatistic = () => {
  const { t } = useTranslation();
  return (
    <div className="col-md-4 pt-5">
      <h2 className="h3 text-light border-bottom pb-3 border-light">
        {t("siteStatistic")}
      </h2>
      <ul className="list-unstyled text-light footer-link-list">
        <li>
          <span className="statistic-title">{t("onlinUsers")}</span>
          <span>2</span>
        </li>
        <li>
          <span className="statistic-title">{t("todayCount")}</span>
          <span>13</span>
        </li>

        <li>
          <span className="statistic-title">{t("yesterdayCount")}</span>
          <span>2</span>
        </li>
        <li>
          <span className="statistic-title">{t("totalUsers")}</span>
          <span>302</span>
        </li>
      </ul>
    </div>
  );
};
