/* eslint-disable import/no-anonymous-default-export */
import { handleActions } from "redux-actions";
import immutable from "immutability-helper";
import { Application } from "@Cores/Constants/Application";

export const appState = {
  authenticated: false,
  requestStatus: false,
  lang: "km",
  menus: [],
  carousels: [],
};

export default {
  application: handleActions(
    {
      [Application.GET_FSA_MENU_SUCCESS]: (state, { payload }) => {
        const menus = payload.menus || undefined;
        return immutable(state, {
          menus: { $set: menus },
        });
      },
      [Application.GET_FSA_CAROUSEL_SUCCESS]: (state, { payload }) => {
        const carousels = payload.carousels || undefined;
        return immutable(state, {
          carousels: { $set: carousels },
        });
      },
      [Application.REQUEST_STATUS]: (state, { payload }) => {
        const requestStatus = payload.requestStatus || undefined;
        return immutable(state, {
          requestStatus: { $set: requestStatus },
        });
      },
      [Application.CHANGE_LANGUAGE]: (state, { payload }) => {
        const lang = payload.lang || "kh";
        return immutable(state, {
          lang: { $set: lang },
        });
      },
    },
    appState
  ),
};
