// @flow
/**
 * @module Actions/App
 * @desc App Actions
 */

import { createActions } from "redux-actions";
import { Application } from "../Constants/Application";

export const { getFsaMenu, getFsaCarousel, changeLanguage } = createActions({
  [Application.GET_FSA_MENU]: () => ({}),
  [Application.GET_FSA_CAROUSEL]: () => ({}),
  [Application.CHANGE_LANGUAGE]: (lang: string) => ({ lang }),
});
