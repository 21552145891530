import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { Helmet } from "react-helmet";
// import NProgress from "nprogress";
import styled, { ThemeProvider } from "styled-components";
import Theme from "@Infrastructure/Themes/Theme";
import BaseRouter from "@Infrastructure/Routes/BaseRoute";

import { Indicator } from "@Cores/Components/Shared/Indicator";

import FSANavbar from "@Cores/Components/Shared/Menu/FSANavbar";
import FSAHeader from "@Cores/Components/Shared/Header/FSAHeader";
import FSAFooter from "@Cores/Components/Shared/Footer/FSAFooter";
// import "@Infrastructure/Themes/fsa.less";

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;

  opacity: 1 !important;
  position: relative;
  transition: opacity 0.5s;
  height: 100%;
  overflow: scroll;
`;

class BaseLayout extends PureComponent {
  static propTypes = {
    app: PropTypes.object.isRequired,
  };
  previousPath = "";

  render() {
    const { app } = this.props;

    return (
      <Fragment>
        <Router>
          <ThemeProvider theme={Theme}>
            <AppWrapper logged={app.authenticated}>
              <Helmet
                defer={false}
                htmlAttributes={{ lang: "pt-br" }}
                encodeSpecialCharacters={true}
                defaultTitle={"Natha Residences"}
                titleTemplate={`%s | Natha Residences`}
                titleAttributes={{ itemprop: "name", lang: "pt-br" }}
              />
              <Indicator loading={app.requestStatus} />
              {/* <FSANavbar />
              <FSAHeader />*/}
              <BaseRouter />
              {/* <FSAFooter /> */}
            </AppWrapper>
          </ThemeProvider>
        </Router>
      </Fragment>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    app: state.application,
    // location: state.router.location,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(BaseLayout);
