import React from "react";
import { Switch, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import PublicRoute from "@Infrastructure/Routes/PublicRoute";
import Websites from "@Websites/";
// HomePage for FSA
// import HomeComponent from "@Websites/FSA/Components/Home/Home.Component";

import { NathaHome } from "@Websites/Natha/Components/Home/Natha.Home";

const BaseRouter = () => {
  const location = useLocation();

  return (
    <TransitionGroup>
      <CSSTransition timeout={300} classNames="fade" key={location.key}>
        <Switch>
          {/* <PublicRoute exact path={`/`} Component={HomeComponent} /> */}
          <PublicRoute exact path={`/`} Component={NathaHome} />

          <PublicRoute path="/" Component={Websites} />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};
export default BaseRouter;
