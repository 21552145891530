import React from "react";
import ReactDOM from "react-dom";
// import ReactGA from "react-ga";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { I18nextProvider } from "react-i18next";
import { store, persistor } from "@Configs/SagaConfig";
import BaseLayout from "@layouts/BaseLayout";
import Loader from "@Components/Loaders/Loader";
import i18n from "@Components/Translator/";

export const Server = {
  cssRetries: 0,
  fetchRetries: 0,

  run() {
    this.render(BaseLayout);

    /* istanbul ignore else */
    if (process.env.NODE_ENV === "production") {
      this.initOfflinePlugin();
    }
  },
  initOfflinePlugin() {
    const OfflinePlugin = require("offline-plugin/runtime");

    /* istanbul ignore next */
    OfflinePlugin.install({
      onUpdateReady: () => {
        OfflinePlugin.applyUpdate();
      },
      onUpdated: () => {
        // store.dispatch(
        //   showAlert(<Reload />, { id: "sw-update", icon: "bolt", timeout: 0 })
        // );
      },
    });
  },
  render(Component) {
    const root = document.getElementById("root");
    if (module.hot) {
      module.hot.accept();
    }
    /* istanbul ignore next */
    if (root) {
      ReactDOM.render(
        <I18nextProvider i18n={i18n}>
          <Provider store={store}>
            <PersistGate
              loading={<Loader fullScreen spinning={true} />}
              persistor={persistor}
            >
              <Component />
            </PersistGate>
          </Provider>
        </I18nextProvider>,
        root
      );
    }
  },
};

Server.run();

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
