import React from "react";
import { Spin } from "antd";

export const Indicator = ({ loading }) => {
  return loading === true ? (
    <div className="indicator">
      <Spin className="shadow" />
    </div>
  ) : (
    <span></span>
  );
};
