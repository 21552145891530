/**
 * @module Sagas/GitHub
 * @desc FSA
 */

import { all, put, takeLatest } from "redux-saga/effects";
import { filterGetAction } from "@Infrastructure/Utils/ActionCreator";
import { FSA } from "@Websites/FSA/Constants/";
import i18n from "@Components/Translator/";

function* onGetArticleSuccess(res) {
  yield put({
    type: FSA.GET_ARTICLES_SUCCESS,
    payload: { articles: res.result },
  });
}
function* onGetArticleDetailSuccess(res) {
  yield put({
    type: FSA.GET_ARTICLES_DETAIL_SUCCESS,
    payload: { article: res.result },
  });
}

function* onGetDepartmentSuccess(res) {
  yield put({
    type: FSA.GET_FSA_DEPARTMENT_SUCCESS,
    payload: { departments: res.result },
  });
}

export function* getArticles({ payload }) {
  const search = payload.search ?? "";
  const option = {
    requestUrl: `articles?title_contains=${search}&_limit=${payload.limit}&_start=${payload.offset}&locale=${i18n.language}`,
    onSuccess: onGetArticleSuccess,
  };
  yield* filterGetAction(option, {});
}

export function* getArticleDetail({ payload }) {
  const option = {
    requestUrl: `articles/${payload.id}?id=${payload.id}&_locale=${i18n.language}`,
    onSuccess: onGetArticleDetailSuccess,
  };
  yield* filterGetAction(option, {});
}

export function* getFsaDepartment() {
  const option = {
    requestUrl: `departments?_locale=${i18n.language}`,
    onSuccess: onGetDepartmentSuccess,
  };
  yield* filterGetAction(option, {});
}

/**
 * Customer Sagas
 */
export default function* root() {
  yield all([takeLatest(FSA.GET_ARTICLES, getArticles)]);
  yield all([takeLatest(FSA.GET_ARTICLES_DETAIL, getArticleDetail)]);
  yield all([takeLatest(FSA.GET_FSA_DEPARTMENT, getFsaDepartment)]);
}
