import * as QueryString from "query-string";
import axios from "axios";
const CLIENT_CREDENTIAL_VALUES =
  "140f26dacafcc514f92b442669d73e07:1a17487c7d2be4e3734bfa9888b1079b4934233894645decacb1e6e172e91ca0";

function sendApiRequest(method, url, data, headers, extra) {
  var defaultHeaders = {
    "Content-Type": "application/json",
    ApiKey: `ACCP ${CLIENT_CREDENTIAL_VALUES}`,
  };
  console.log("TEst", defaultHeaders);
  if (extra == null) {
    extra = {};
  }
  const requestOptions = {
    method,
    url,
    headers: defaultHeaders,
    data,
    ...extra,
  };
  //error ip
  requestOptions.url = `${process.env.REACT_APP_API_URL}/${requestOptions.url}`;

  return axios(requestOptions)
    .then((response) => {
      return {
        success: true,
        result: response.data,
      };
    })
    .catch((err) => {
      return {
        success: false,
        result: err,
      };
    });
}

function sendRequest(method, url, data, headers, extra) {
  // const token = getAccessToken();
  var defaultHeaders = {
    "Content-Type": "application/json",
    ApiKey: `ACCP ${CLIENT_CREDENTIAL_VALUES}`,
  };

  // if (headers !== null || headers !== undefined) {
  //   defaultHeaders = _.merge(headers, defaultHeaders);
  // } else if (token !== null && !headers.Authorization) {
  //   defaultHeaders.Authorization = `Bearer ${token.accessToken}`;
  // }

  if (extra == null) {
    extra = {};
  }
  const requestOptions = {
    method,
    url,
    headers: defaultHeaders,
    data,
    ...extra,
  };

  //error ip
  requestOptions.url = `${process.env.REACT_APP_API_URL}/${requestOptions.url}`;

  return axios(requestOptions)
    .then((response) => {
      return {
        success: true,
        result: response.data,
      };
    })
    .catch((err) => {
      return {
        success: false,
        result: err,
      };
    });
}

export function GET(url, data, headers, extra) {
  if (data) {
    const query = QueryString.stringify(data, { arrayFormat: "bracket" });
    if (url && url.indexOf("?") !== -1) {
      url = `${url}&${query}`;
    } else {
      url = `${url}?${query}`;
    }
  }
  return sendRequest("GET", url, null, headers, extra);
}

export function POST(url, data, withAuthentication, headers, extra) {
  if (withAuthentication === true)
    return sendRequest("POST", url, data, headers, extra);
  else {
    return sendApiRequest("POST", url, data, headers, extra);
  }
}

export function PUT(url, data, headers) {
  return sendRequest("PUT", url, data, headers);
}

export function DELETE(url, data, headers) {
  return sendRequest("DELETE", url, data, headers);
}
