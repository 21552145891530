import React from "react";
import { Carousel } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export const NathaHomeWood = () => {
  const { t } = useTranslation();
  return (
    <section className="natha-page natha-page-wood">
      <div className="row">
        <div className="col-12 col-md-8 col-lg-9 col-sm-12 natha-thewood-image">
          <Carousel>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={`${process.env.PUBLIC_URL}/assets/img/thewood-1.jpg`}
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={`${process.env.PUBLIC_URL}/assets/img/thewood-2.jpg`}
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={`${process.env.PUBLIC_URL}/assets/img/thewood-3.jpg`}
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={`${process.env.PUBLIC_URL}/assets/img/thewood-4.jpg`}
                alt=""
              />
            </Carousel.Item>
          </Carousel>
        </div>
        <div className="col-12 col-md-4 col-lg-3 col-sm-12 natha-thewood-content">
          <h2 className="natha-thewood-title"> {t("nathaTheWoodTitle")}</h2>
          <h3 className="natha-thewood-slogan">{t("nathaInteriorSlogan")}</h3>
          <p>{t("nathaTheWoodDescription")}</p>
          {/* <span className="next-page">+</span> */}
        </div>
      </div>
    </section>
  );
};
