// @flow
/**
 * @module Actions/App
 * @desc App Actions
 */

import { createActions } from "redux-actions";
import { FSA } from "../Constants/";

export const { getArticles, getArticlesDetail, getFsaDepartment } =
  createActions({
    [FSA.GET_ARTICLES]: (search: string, limit: number, offset: number) => ({
      search,
      limit,
      offset,
    }),
    [FSA.GET_ARTICLES_DETAIL]: (id: number) => ({ id }),
    [FSA.GET_FSA_DEPARTMENT]: () => ({}),
  });
