import { applyMiddleware, createStore, compose, combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import RouteHistory from "@Utils/RouteHistory";
import rootSaga from "./RootSagaConfig";
import rootReducer from "./RootReducerConfig";

import Middleware, {
  sagaMiddleware,
} from "@Infrastructure/Middlewares/SagaMiddleware";

const reducer = persistReducer(
  {
    key: "rrsb", // key is required
    storage, // storage is now required
    whitelist: ["app", "user"],
  },
  combineReducers({
    router: connectRouter(RouteHistory),
    ...rootReducer,
  })
);

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/* istanbul ignore next */
const configStore = (initialState = {}) => {
  const store = createStore(
    reducer,
    initialState,
    composeEnhancer(applyMiddleware(...Middleware))
  );

  sagaMiddleware.run(rootSaga);

  return {
    persistor: persistStore(store),
    store,
  };
};

const { store, persistor } = configStore();

global.store = store;

export { store, persistor };
