import React from "react";
import { useTranslation } from "react-i18next";

// Images
import BannerImage from "@Infrastructure/Assets/Natha/Images/banner.jpg";

import "./NathaBanner.less";

export const NathaBanner = () => {
  const { t } = useTranslation();
  return (
    <section
      className="natha-page natha-banner natha-page-banner"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/banner.jpg)`,
      }}
    >
      <div className="liquid-row-overlay"></div>
      <div className="banner-text-content">
        <h2 className="banner-title"> {t("nathaResidence")}</h2>
        <h3 className="banner-slogan">{t("nathaHomeSlogan")}</h3>
        <p className="banner-description">{t("nathaHomeDescription")}</p>
      </div>
    </section>
  );
};
