import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import Async from "@Components/Async/";

//imports

const AsyncFSA = Async({
  loader: () => import("./FSA/"),
});

const AsyncNatha = Async({
  loader: () => import("./Natha/"),
});

class Websites extends React.Component {
  render() {
    return (
      <Fragment>
        <Switch>
          <Route path="/fsa" component={AsyncFSA} />
          <Route path="/natha" component={AsyncNatha} />
        </Switch>
      </Fragment>
    );
  }
}

Websites.propTypes = {
  dispatch: PropTypes.func,
};

Websites.defaultProps = {
  loading: true,
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProp = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProp)(Websites);
